import SignIn from './components/auth/SignIn.vue'
import Auth from './components/auth/Auth.vue'
import Core from './components/Core.vue'

import Dashboard from './components/contents/Dashboard.vue'
import Routes from './components/contents/routes/Routes.vue'
import Schedule from './components/contents/Schedule.vue'

import Users from './components/contents/users/Users.vue'
import Cargos from './components/contents/cargos/Cargos.vue'
import EmployeeDetail from './components/contents/employees/EmployeeDetail.vue'

export const routes = [
    {
        path: '/authentication', component: Auth,
        beforeEnter: (to, from, next) => {
            if (!localStorage.getItem('userId')) {
                next()
            } else {
                next('/dashboard')
            }
        },
        children: [
            {
                path: '', component: SignIn, name: 'login',
            },
            {
                path: 'signup', component: () => import("./components/auth/SignUp.vue"), name: 'add-employee'
            },
        ]
    },

    {
        path: '/dashboard', component: Core,
        beforeEnter: (to, from, next) => {
            if (localStorage.getItem('userId')) {
                next()
            } else {
                next({name: 'login'})
            }
        },
        children: [
            { path: '', component: Dashboard, name: 'dashboard' },
            { path: '/routes', component: Routes, name: 'routes' },
            { path: '/schedule', component: Schedule, name: 'schedule' },
            {
                path: '/employees', component: () => import("./components/contents/employees/StartPage.vue"),
                children: [
                    {
                        path: '', component: () => import("./components/contents/employees/Employees.vue"), name: 'employees'
                    },

                    {
                        path: ':e_id', component: EmployeeDetail, name: 'employee-detail'
                    },
                ]
            },
            { path: '/users', component: Users, name: 'users' },
            { path: '/cargo', component: Cargos, name: 'cargos' },
            { path: '/userinfo', component: ()=> import("./components/contents/userinfo/UserInfo.vue"), name: 'userinfo'}
        ]
    },
    { path: '/', redirect: { name: "dashboard" } },
    { path: '*', redirect: { name: "dashboard" } },
]