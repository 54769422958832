<template>
  <div>
    <div class="flex flex-col min-h-screen bg-red-500">
      <div
        class="
          sm:flex
          flex-row-reverse
          sm:h-10
          text-white
          justify-between
          items-center
        "
      >
        <user class="w-full flex justify-between sm:justify-end items-center" />
        <logo
          :collapse="collapse"
          :isCollapsed="isCollapsed"
          :class="['flex w-full items-center', 'sm:' + collapse]"
        />
      </div>
      <div class="flex">
        <div class="bg-white" v-show="isCollapsed">
          <sidebar />
        </div>

        <div class="bg-blue-50 min-h-screen flex-grow flex-col">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./header/Logo.vue";
import User from "./header/User.vue";
import Sidebar from "./sidebar/Sidebar.vue";

export default {
  data: () => {
    return {
      isCollapsed: window.innerWidth< 640 ? false : true ,
    };
  },
  methods: {
    collapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  components: { Sidebar, Logo, User },
};
</script>



<style scoped>
</style>