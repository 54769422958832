<template>
  <div class="flex h-screen justify-center items-center">
    <loading />
    <div class="sm:p-8 sm:shadow-md w-80 flex flex-col items-start">
      <span class="text-blue-600 text-2xl font-semibold mb-6 mt-2"
        >Нэвтрэх</span
      >
      <input
        type="mail"
        v-model="email"
        placeholder="Нэвтрэх нэр / утасны дугаар"
        class="input"
      />
      <input
        @keyup.enter="SignIn"
        type="password"
        v-model="password"
        placeholder="Нууц үг"
        class="input"
      />

      <label class="flex justify-start items-center mb-2 font-semibold">
        <input type="checkbox" class="mr-2" />
        Сануулах
      </label>

      <button @click="SignIn" class="button mb-2">Нэвтрэх</button>
      <button @click="SignUp" class="button">Бүртгүүлэх</button>

      <div @click="ForgotUser" class="my-4 cursor-pointer text-center">
        <span class="text-blue-600 underline"
          >Нэвтрэх нэр эсвэл нууц үгээ мартсан уу?</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../assets/Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    SignIn() {
      this.email &&
        this.password &&
        this.$store.dispatch("authModule/signin", {
          email: this.email,
          password: this.password,
        });
    },
    SignUp() {
      this.$router.push({ name: "add-employee" });
    },
    ForgotUser() {
      alert("Forgot Username and Password Modal");
    },
  },
};
</script>

<style>
</style>