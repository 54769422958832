<template>
  <div>
    <button
      @click="logout"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      class="button flex items-center justify-center px-2"
    >
      <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="text-lg" />
      <span :class="['pl-2']">Гарах</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    showTxt: {},
    mouseOver: { type: Function },
    mouseLeave: { type: Function },
    menus: {},
  },
  methods: {
    logout() {
      this.$store.dispatch("authModule/logout");
    },
  },
};
</script>

<style>
</style>