<template>
  <div class="h-full">
    <modal v-show="isVisible" @close="sendFormInfo">
      <template v-slot:header>Бараа нэмэх</template>
      <template v-slot:body>
        <add-box />
      </template>
    </modal>
    <div class="flex h-full">
      <div
        :class="[
          'px-2 py-4 2xl:px-4 w-full min-h-screen',
          shipDetail && 'hidden sm:flex flex-col',
        ]"
      >
        <div class="flex justify-between mb-2">
          <div class="ml-2 text-xl">Нислэг</div>
          <button
            class="button flex items-center max-w-max px-4 mr-2 font-normal"
            @click="openForm"
          >
            <!-- <font-awesome-icon :icon="['fas', 'plus-square']" class="text-lg mr-4" /> -->
            <div class="mr-4">
              <div
                class="
                  flex
                  items-center
                  justify-center
                  rounded-sm
                  w-5
                  h-5
                  border-2 border-white
                "
              >
                <div class="text-lg font-semibold leading-relaxed">+</div>
              </div>
            </div>
            <span>Нислэг нэмэх</span>
          </button>
        </div>
        <filter-component
          :allCount="all"
          :goingCount="goingCount"
          :doneCount="doneCount"
          :statusTxt="statusTxt"
          :changeStatusTxt="changeStatusTxt"
          class="mb-2"
        />
        <route-list :ships="filteredShips" :setShipDetail="setShipDetail" />
      </div>
      <div
        :class="[
          'sm:bg-white w-full px-2 2xl:px-4 py-4',
          !shipDetail && 'hidden sm:flex-col',
        ]"
      >
        <div @click="back" class="sm:hidden">back</div>
        <route :shipDetail="shipDetail" :ships="ships" />
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../assets/Modal.vue";
import AddBox from "./AddBox.vue";
import Filter from "./Filter.vue";
import Route from "./Route.vue";
import RouteList from "./RouteList.vue";
export default {
  data: () => {
    return {
      isVisible: false,
      shipDetail: "",
      // all: {},
      statusTxt: null,
      ships: [
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Moscow",
            address:
              "Бла БлаБла jjk kjsadh fkjd fkshd, 3-р хороо, 25, 605 тоот",
            country: "Russia",
          },
          user: "Odbayar Baasandorj",
          status: "done",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj",
          status: "done",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj",
          status: "done",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj",
          status: "done",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj",
          status: "done",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj going 1",
          status: "going",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj",
          status: "done",
        },
        {
          shipId: Math.random().toString().slice(2, 11),
          from: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          to: {
            city: "Улаанбаатар",
            address: "БЗД, 3-р хороо, 25, 605 тоот",
            country: "Mongolia",
          },
          user: "Odbayar Baasandorj going 2",
          status: "going",
        },
      ],
    };
  },
  components: {
    Route,
    RouteList,
    FilterComponent: Filter,
    Modal,
    AddBox,
  },
  methods: {
    setShipDetail(shipId) {
      this.shipDetail = shipId;
    },
    changeStatusTxt(value) {
      this.statusTxt = value;
    },
    openForm() {
      this.isVisible = true;
    },
    sendFormInfo() {
      this.isVisible = false;
    },
    back() {
      this.shipDetail = 0;
    },
  },

  computed: {
    all() {
      return this.ships.length;
    },
    goingCount() {
      return this.ships.filter((el) => el.status === "going").length;
    },
    doneCount() {
      return this.ships.filter((el) => el.status === "done").length;
    },
    filteredShips() {
      console.log(this.ships);
      return this.ships.filter((el) => {
        return this.statusTxt === null
          ? true
          : el.status.toLowerCase() === this.statusTxt.toLowerCase();
      });
    },
  },

  created() {
    this.shipDetail =
      window.innerWidth < 640 ? "" : this.ships[this.ships.length - 1].shipId;
  },
};
</script>

<style>
</style>