<template>
  <div class="flex min-w-max flex-col justify-between py-2 px-2 2xl:px-4">
    <div>
      <!-- logo section -->

      <Menus
        :menus="menus"
        :showTxt="showTxt"
        :mouseLeave="mouseLeave"
        :mouseOver="mouseOver"
      />
    </div>
    <div class="mb-4">
      <logout
        :showTxt="showTxt"
        :mouseLeave="mouseLeave"
        :mouseOver="mouseOver"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Logout from "../auth/Logout.vue";
import Menus from "./Menus.vue";
export default {
  data: () => {
    return {
      showTxt: false,
      title: "Cargo Delivery",
      menus: null
    };
  },
  components: {
    Logout,
    Menus,
  },
  methods: {
    mouseOver() {
      this.showTxt = true;
    },
    mouseLeave() {
      this.showTxt = false;
    },
  },
  created() {
    axios.post(`getAccountMenu/${this.$store.state.authModule.roleId}`)
    .then(resp => {
      console.log(resp)
      this.menus=resp.data
    })
  }
};
</script>

<style>
</style>