<template>
  <div class="sm:flex justify-between w-full">
    <div class="sm:w-1/2">
      <login />
    </div>
    <div class="hidden sm:inline-flex w-1/2">
      <about-component />
    </div>
  </div>
</template>

<script>
import About from "./About.vue";
import Login from "./Login.vue";
export default {
  components: { Login, AboutComponent: About },
};
</script>

<style>
</style>