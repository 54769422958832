<template>
  <div class="ml-2 flex">
    <div @click="changeStatusTxt(null)" :class="['btn', statusTxt === null && 'active']">
      <span class="px-1 text-sm" >Бүгд</span>
      <div>
        <span>{{allCount}}</span>
      </div>
    </div>
    <div @click="changeStatusTxt('going')" :class="['btn', statusTxt === 'going' && 'active']">
      <span class="px-1 text-sm">Замдаа гарсан</span>
      <div>
        <span>{{goingCount}}</span>
      </div>
    </div>
    <div @click="changeStatusTxt('done')" :class="['btn', statusTxt === 'done' && 'active']">
      <span class="px-1 text-sm">Ачаа ачиж байгаа</span>
      <div>
        <span>{{doneCount}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goingCount: {type: Number},
    allCount: {type: Number},
    doneCount: {type: Number},
    statusTxt: {},
    changeStatusTxt: {type: Function}
  },
};
</script>

<style lang="postcss" scoped>
.btn {
  @apply flex 
  justify-center 
  items-center 
  mr-1
  p-1 
  rounded-sm 
  bg-gray-200 
  border-2 
  border-blue-50 
  hover:border-gray-200 
  font-semibold 
  text-gray-700 
  cursor-pointer;
}

.active {
  @apply bg-blue-100 text-blue-600 hover:border-blue-100;
}

.btn div {
  @apply bg-gray-300
          w-5
          h-5
          rounded-sm
          text-xs
          flex
          items-center
          justify-center;
}
.active div {
  @apply bg-blue-200
  text-blue-700;
}
</style>