<template>
  <div class="flex flex-wrap">
    <div v-for="ship in ships" :key="ship.shipId" class="p-2 w-full lg:w-1/2">
      <div
        @click="setShipDetail(ship.shipId)"
        class="bg-white p-4 rounded-md cursor-default"
      >
        <div class="flex items-center">
          <div>
            <div class="text-gray-400 text-sm">Нислэгийн дугаар</div>
            <div class="font-bold">{{ "UA-" + ship.shipId }}</div>
          </div>
          <div class="ml-4">car image</div>
        </div>
        <div class="line"></div>
        <div>
          <div class="flex items-center mb-4">
            <div class="min-w-max">
              <div
                class="
                  w-7
                  h-7
                  flex
                  justify-center
                  items-center
                  bg-green-100
                  rounded-full
                  mr-3
                "
              >
                <font-awesome-icon
                  :icon="['fas', 'arrow-up']"
                  class="transform rotate-45 text-sm text-green-400"
                />
              </div>
            </div>

            <div>
              <div class="text-sm font-semibold">
                {{ ship.from.city + ", " + ship.from.country }}
              </div>
              <div class="text-xs text-gray-500 font-semibold">
                {{ ship.from.address }}
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <div class="min-w-max">
              <div
                class="
                  w-7
                  h-7
                  flex
                  justify-center
                  items-center
                  bg-blue-100
                  rounded-full
                  mr-3
                "
              >
                <font-awesome-icon
                  :icon="['fas', 'arrow-right']"
                  class="transform rotate-45 text-sm text-blue-400"
                />
              </div>
            </div>

            <div>
              <div class="text-sm font-semibold">
                {{ ship.to.city + ", " + ship.to.country }}
              </div>
              <div class="text-xs text-gray-500 font-semibold">
                {{ ship.to.address }}
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>

        <div>Жолоочийн мэдээлэл</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ships: [],
    setShipDetail: { type: Function },
  },
};
</script>

<style>
</style>