// import { router } from "../../main"

import { router } from "../../main"
import axios from "axios"
import { Base64 } from "js-base64"
import md5 from "js-md5"
export const auth = {
    namespaced: true,
    state: {
        idToken: null,
        userId: null,
        roleId: null,
        userEmail: null,
        info: null
    },
    getters: {

    },
    mutations: {
        authUser(state, payload) {
            state.userEmail = payload.email
            state.userId = payload.userId
            state.roleId = payload.roleId === "null" ? null : payload.roleId
            state.info = payload.info === "null" ? null : payload.info
        },
        clearAuth(state) {
            state.userId = null,
                state.roleId = null,
                state.userId = null,
                state.userEmail = null,
                state.info = null
        },
    },
    actions: {
        signin({ commit, dispatch }, authData) {
            dispatch('Loading', true, { root: true })
            axios.post('loginAccount', {
                email: authData.email,
                password: Base64.encode(md5(authData.password))
            })
                .then(resp => {
                    console.log(resp);
                    if (resp.data.status === 1) {
                        localStorage.setItem('email', authData.email)
                        localStorage.setItem('userId', resp.data.id)
                        localStorage.setItem('roleId', resp.data.roleId)
                        localStorage.setItem('info', resp.data.info)
                        commit('authUser', { email: authData.email, userId: resp.data.id, roleId: resp.data.roleId, info: resp.data.info })
                        router.push({ name: 'dashboard' })
                    } else {
                        alert(resp.data.msg)
                    }
                    dispatch('Loading', false, { root: true })
                })
                .catch(err => {
                    console.log("a: " + err)
                    dispatch('Loading', false, { root: true })
                    // alert("Хэрэглэгч олдсонгүй")
                })
        },
        logout({ commit }) {
            localStorage.removeItem('userId')
            localStorage.removeItem('roleId')
            localStorage.removeItem('email')
            localStorage.removeItem('info')
            commit('clearAuth')
            router.replace({ name: 'login' })
        },
        autoLogin({ commit }) {
            if (!localStorage.getItem('userId')) {
                return
            }
            commit('authUser', {
                email: localStorage.getItem('email'),
                userId: localStorage.getItem('userId'),
                roleId: localStorage.getItem('roleId'),
                info: localStorage.getItem('info')
            })
        }
    }
}