<template>
  <div id="app" class="flex flex-col h-screen" >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  beforeCreate() {
    this.$store.dispatch('authModule/autoLogin')
  }
};
</script>

<style>
</style>
