<template>
  <div class="h-10 px-2 bg-blue-300">
    <router-link :to="{ name: 'userinfo' }">
      <div class="px-2 cursor-pointer">
        {{ $store.state.authModule.userEmail }}
      </div>
    </router-link>
    <div @click="$store.dispatch('authModule/logout')" class="cursor-pointer">
      Logout
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { router } from '../../main';
export default {
  // methods: {
  //   getUserInfo() {
  //     axios
  //       .post(`userAccount/${this.$store.state.authModule.userId}`)
  //       .then((resp) => {
  //         console.log(resp);
  //         if (this.$route.path !== "") {
  //         }
  //         this.$router.push({ name: "userinfo" });
  //       })
  //       .catch((err) => console.log(err));
  //   },
  // },
};
</script>

<style>
</style>