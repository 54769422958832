<template>
  <div class="flex flex-col">
    <router-link
      :to="{ name: `${menu.routeName}` }"
      v-for="(menu, index) in menus"
      :key="index"
      v-slot="{ href, navigate, isActive }"
      custom
    >
      <a :href="href" @click="navigate">
        <div
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
          :class="[
            isActive && 'bg-blue-50 rounded-sm text-blue-600',
            'hover:bg-gray-50 flex py-2 px-2 2xl:pl-4 2xl:pr-8 items-center text-gray-500 font-semibold',
          ]"
        >
          <div class="w-8 text-center">
            <font-awesome-icon
              :icon="['fas', menu.icon]"
              :class="[
                isActive && 'text-blue-600',
                'text-2xl text-gray-400',
              ]"
            />
          </div>
          <div :class="['px-2']">
            {{ menu.name }}
          </div>
        </div>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    showTxt: {},
    mouseOver: { type: Function },
    mouseLeave: { type: Function },
    menus: {},
  },
};
</script>

<style >
</style>