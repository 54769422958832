<template>
  <div class="h-full w-full bg-blue-50"></div>
</template>

<script>
export default {

}
</script>

<style>

</style>