import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./modules/authentication"
import { employees } from "./modules/employees"

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        loading: false,
        loadingMsg: "LOADING..."
    },
    getters: {
    },
    mutations: {
        LoadingData(state, payload) {
            state.loading = payload
        }
    },
    actions: {
        Loading({ commit }, payload) {
            commit('LoadingData', payload)
        }
    },
    modules: {
        authModule: auth,
        employees
    }
})