import axios from "axios"

export const employees = {
    namespaced: true,
    state: {
        employees: [],
        employeeIds: [],
    },
    getters: {
        employee: (state) => (id) => {
            return state.employees.find(employee => employee.employeeId === id)
        }
    },
    mutations: {
        ids(state, ids) {
            state.employeeIds = ids
        },
        removeEmployees(state) {
            // state.employees = state.employees.filter(
            //     el => !employeeIds.includes(el.useraccount_id)
            // )
            state.employeeIds = []
        },
        updateEmployee(state, employee) {
            state.employees.map(el => {
                if (el.id === employee.employeeId) {
                    el.fName = employee.fName
                    return
                }
                console.log(state.employees)
            });
            console.log("mutation: " + state.employees)
        },
        setEmployees(state, payload) {
            state.employees = payload
        }
    },
    actions: {
        removeEmployees({ commit, dispatch }, employeeIds) {
            commit('removeEmployees', employeeIds)
            axios.post('deleteAccount', employeeIds)
                .then(resp => {
                    console.log(resp.data)
                    dispatch('getEmployees')
                })
                .catch()
        },
        updateEmployee({ commit }, employee) {
            commit('updateEmployee', employee)
        },
        getEmployees({ commit }) {
            axios.post('users').then(resp => {
                commit('setEmployees', resp.data)
            }).catch(err => console.log(err))

        }
    }
}