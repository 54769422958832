<template>
  <div>
    <div v-for="employee in employees" :key="employee.employeeId">
      {{ employee.fName }}
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return { employees: [] };
  },
  created() {
    this.employees = this.$store.state.employees.employees;
  },
};
</script>

<style>
</style>