import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import App from './App.vue'

import './index.css'
import { routes } from './routes'
import { store } from './store/index'
import axios from 'axios'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faUserSecret,
  faHome,
  faCalendarAlt,
  faPlane,
  faArrowUp,
  faArrowRight,
  faBoxOpen,
  faSignOutAlt,
  faPlusSquare,
  faUsers,
  faIdCardAlt,
  faBoxes,
  faTrashAlt,
  faEdit,
  faBars,
  faTimes,
  faInfo as faUserCog
} from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare as farPlusQuare, faUser as farUser } from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faUserSecret,
  faHome,
  faCalendarAlt,
  faPlane,
  faArrowUp,
  faArrowRight,
  faBoxOpen,
  faSignOutAlt,
  faPlusSquare,
  farPlusQuare,
  farUser,
  faUsers,
  faIdCardAlt,
  faBoxes,
  faTrashAlt,
  faEdit,
  faBars,
  faTimes,
  faUserCog
)


// Axios default global configurations

axios.defaults.baseURL = 'http://103.143.40.226:8084/api/';


/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueResource);

export const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  console.log(`from ${from.name} to ${to.name}`)
  // if (to.name !== "login") next({ name: 'login' })
  // else next()
  next()
})

new Vue({
  router,
  store,

  render: h => h(App),
}).$mount('#app')
