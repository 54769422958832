<template>
  <div
    v-show="this.$store.state.loading"
    class="
      fixed
      z-50
      top-0
      -left-0
      w-full
      h-full
      bg-black
      opacity-70
      flex
      items-center
      justify-center
    "
  >
    <span class="bg-white text-black p-4">{{ this.$store.state.loadingMsg }}</span>
  </div>
</template>

<script>
export default {
    
};
</script>

<style>
</style>