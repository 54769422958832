var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},_vm._l((_vm.menus),function(menu,index){return _c('router-link',{key:index,attrs:{"to":{ name: ("" + (menu.routeName)) },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('div',{class:[
          isActive && 'bg-blue-50 rounded-sm text-blue-600',
          'hover:bg-gray-50 flex py-2 px-2 2xl:pl-4 2xl:pr-8 items-center text-gray-500 font-semibold' ],on:{"mouseover":_vm.mouseOver,"mouseleave":_vm.mouseLeave}},[_c('div',{staticClass:"w-8 text-center"},[_c('font-awesome-icon',{class:[
              isActive && 'text-blue-600',
              'text-2xl text-gray-400' ],attrs:{"icon":['fas', menu.icon]}})],1),_c('div',{class:['px-2']},[_vm._v(" "+_vm._s(menu.name)+" ")])])])]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }