<template>
  <div>
    <div class="ml-2 mb-2 text-xl">Нислэгийн дэлгэрэнгүй</div>
    <div>{{ shipDetail }}</div>
    <div>{{ ship }}</div>

  </div>
</template>

<script>
export default {
  props: ["shipDetail", "ships"],
  computed: {
    ship() {
      return this.ships.find(ship => ship.shipId === this.shipDetail)
    }
  },
};
</script>

<style>
</style>