<template>
  <div class="max-w-max">
    <div>EmployeeDetail: {{ $route.params.id }}</div>
    <div>
      Ажилтны нэр: {{ employee(id).lName + " овогтой " + employee(id).fName }}
    </div>
    <div class="flex items-center">
      <label for="lName" class="pr-2"> Нэр: </label>
      <input
        id="lName"
        type="text"
        v-model="employee(id).fName"
        class="input"
      />
    </div>
    <button class="button">Засах</button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {
      id: null,
    };
  },
  methods: {},
  computed: {
    ...mapGetters({ employee: "employees/employee" }),
  },
  created() {
    this.id = this.$route.params.e_id;
  },
};
</script>

<style>
</style>