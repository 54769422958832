<template>
  <div class="h-10" >
    <div @click="collapse" class="cursor-pointer h-full px-2 bg-blue-400 flex items-center">
      <div class="w-8 h-full flex items-center justify-center" >
        <font-awesome-icon :icon="['fas', 'bars']" v-show="!isCollapsed" class="text-lg" />
        <font-awesome-icon :icon="['fas', 'times']" v-show="isCollapsed" class="text-lg" />
      </div>
    </div>
    <div class=" text-lg h-full flex  px-2 items-center w-full bg-white sm:bg-blue-300 text-gray-500 sm:text-white font-semibold" >Logo</div>
  </div>
</template>

<script>
export default {
  props: [
    'collapse',
    'isCollapsed'
  ],
};
</script>

<style>
</style>